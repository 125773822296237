import httpClient  from '.';
const API_CONTROLLER = 'report-driver/';

export default {
    getReportSummaryDriver(search) {
        let url = API_CONTROLLER + 'get-report-summary-by-driver';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                driverId: search.driverId
            }
        });
    },

    getReportSalaryDriver(search) {
        let url = API_CONTROLLER + 'get-report-salary-by-driver';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                driverId: search.driverId,
                keyword: search.keyword
            }
        });
    },

    getReportFuelByDriver(search) {
        let url = API_CONTROLLER + 'get-report-summary-fuel-by-driver';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                driverId: search.driverId
            }
        });
    },
}